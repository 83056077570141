/* Participants.css */
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@200..800&display=swap');

body, * {
  font-family: "Nunito", sans-serif;
}

.participants-container {
  padding: 0; /* Remove padding to align with header */
  position: relative;
  background-color: #EEF2F8;
  min-height: 100vh;
  
}

.participant-card.selected {
  border: 1px solid #aaaaaa;
  transition: background-color 0.3s ease;
}

.participant-builder-header {
  font-family: 'Nunito', sans-serif;
  position: relative;
  z-index: 1;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  background-color: #ffffff;
  color: #ffffff;
  height: 40px;
  padding: 10px;
  padding-right: 20px;
  position: fixed;
  border: 1px solid #a2a2a2;

}

.recruit-empty-message {
  text-align: center;
  color: #777;
  margin:200px;
  font-size: 24px;
  font-weight: 300;
}

.participants-header {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  background-color: #625DEC;
  position: sticky;
  top: 0;
  z-index: 1000;
  height: 40px;
  color: #ffffff;
  font-weight: 500;
}

.card-actions-container {
  position: absolute;
  top: 10px;
  right: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  height: 100%;
}

.card-actions {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
}

.invite-button {
  background-color: #625DEC;
  color: #ffffff;
  padding: 5px 10px;
  border: none;
  border-radius: 8px;
  height: 20px;
  width: 70px;
  cursor: pointer;
  margin-bottom: 5px;
  align-items: center;
}

.invite-button:hover {
  background-color: #5A54D2;
}

.disabled-button {
  background-color: #000000;
  color: #ffffff;
  padding: 5px 10px;
  border: none;
  border-radius: 4px;
  cursor: not-allowed;
  margin-bottom: 5px;
}


.back-button {
  background-color: transparent;
  border: 1px solid rgb(255, 255, 255); 
  cursor: pointer;
  color: #ffffff;
  display: flex;
  transition: background-color 0.3s ease;
  box-shadow: none; 
  margin-right: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  font-size: 16px;
}

.back-button:hover {
  background-color: #ffffff;
  color:#625DEC ;
}

.invite-button:hover {
  background-color: #5A54D2;
}

.applied-date {
  font-size: 12px;
  color: #aaaaaa;
  font-weight: 400;
  margin-top: 4px;
}


.header-title {
  font-size: 24px;
  font-weight: 500;
  color: #ffffff;
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 70px); /* Adjust height to account for header */
}

.loader {
  border: 8px solid #f3f3f3;
  border-top: 8px solid #625DEC;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 1s linear infinite;
  align-items: center;
  align-self: center;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.participants-list {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  align-items: flex-start; /* Center participant cards */
  margin-left: 40px;
  margin-top: 60px;
}

.participant-card {
  background-color: #ffffff;
  padding: 20px;
  cursor: pointer;
  position: relative;
  display: flex;
  flex-direction: column;
  width: 1000px;
  padding-right: 120px; /* Adjust padding to make space for the buttons */
  border: 1px solid #00000019;
  border-radius: 16px;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.1);
}

.participant-card:hover {
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2);
}

.participant-header {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.profile-id-section {
  margin-left: 10px;
  margin-top: 0px;
  font-size: 12px;
  color: #9a9a9a;
  display: flex;
  

}


.profile-pic {
  background-color: #625DEC4f;
  color: #060606;
  width: 30px;
  height: 30px;
  border-radius: 25px;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
}

.participant-name {
  font-size: 20px;
  font-weight: bold;
  color: #333333;
}

.profile-info-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr; /* Three columns */
  gap: 10px 20px; /* Row gap of 10px, column gap of 20px */
}

.info-item {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #555555;
}

.info-item svg {
  margin-right: 8px;
}

/* Side Sheet with Slide Animation */

.side-sheet {
  position: fixed;
  top: 0;
  right: 0;
  width: 500px;
  max-width: 90%;
  height: 100%;
  background-color: #ffffff;
  overflow-y: auto;
  box-shadow: -2px 0 4px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  transform: translateX(100%);
  transition: transform 0.3s ease-in-out;
}

.side-sheet.open {
  transform: translateX(0);
}



.side-sheet-header {
  font-family: 'Nunito', sans-serif;
  position: relative;
  z-index: 1;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  background-color: #ffffff;
  color: #ffffff;
  height: 40px;
  padding: 10px;
  padding-right: 20px;
  border-bottom: 1px solid #d3d3d3
}
.side-sheet-header h2{
  margin: 0px;
  color: #000000;
}



.side-sheet-header-close-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35px;
  height: 100%;
  font-size: 16px;
  background-color: transparent;
  border: 1px solid #ffffff; 
  cursor: pointer;
  color: #ffffff; 
  margin:0px;
  padding: 0px;
  padding-left: 10px;
  margin-right: 30px;
  border-left: 1px solid #c4c4c4;
  border-radius: 0px;
  margin-left: auto;
}

.close-button:hover {
  background-color: #ffffff;
  color:#625DEC ;
}

.screener-responses {
  margin-top: 20px;
  padding: 20px;
}

.response-item {
  margin-bottom: 20px;
}

.view-button {
  background-color: #625DEC;
  color: #ffffff;
  padding: 5px 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.view-button:hover {
  background-color: #5A54D2;
}


.more-options {
  position: relative;
  cursor: pointer;
  font-size: 24px;
  width: 30px;
  height: 30px;
  align-items: center;
  align-self: center;
}

.action-menu-participants {
  position: absolute;
  top: 30px; /* Position the menu right below the button */
  left: 10px; /* Align it with the left of the button */
  z-index: 100000;
  width: auto;
  background-color: white;
  border: 1px solid #000000;
  border-radius: 4px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  white-space: nowrap;
  overflow: visible;
}


.action-menu-participants  button {
  display: block;
  width: 100%;
  padding: 10px 15px;
  text-align: left;
  background: none;
  border: none;
  font-family: 'Nunito', sans-serif;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.action-menu-participants button:hover {
  background-color: #f5f5f5;
}

.action-menu-participants  button.action-menu-participants-option {
  color: #585858;
}

.action-menu-participants  button.action-menu-participants-option:hover {
  background-color: rgba(0, 0, 0, 0.1); 
}

.pending-button {
  background-color: #625DEC;
  color: white;
  height: 30px;
  width: 100px;
  font-size: 12px;
  font-weight: bold;
  padding: 5px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.linkedin-link {
  margin-left: 8px;
  color: #0077B5; /* LinkedIn blue */
  font-size: 16px;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
}

.linkedin-link:hover {
  color: #005582; /* Darker blue on hover */
}
.source-info {
  font-size: 10px;
  color: #aaaaaa;
  font-weight: 300;
  display: flex;
  align-items: center;
  gap: 4px;
  margin-top: 48px;
}

.provider-logo {
  height: 12px;
  width: auto;
  vertical-align: middle;
}



.invited-button {
  background-color: #137494;
  color: white;
  height: 30px;
  width: 100px;
  font-size: 12px;
  font-weight: bold;
  padding: 5px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}


.completed-button {
  background-color: #63CE50;
  color: white;
  height: 30px;
  width: 100px;
  font-size: 12px;
  font-weight: bold;
  padding: 5px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.reported-button {
  background-color: #FD6851;
  color: white;
  width: 100px;
  height: 30px;
  font-size: 12px;
  font-weight: bold;
  padding: 5px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}


/* Search and Filter Container */
.search-filter-container {
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 20px;
  padding-left: 60px;
  background-color: #ffffff;
  border-bottom: 1px solid #e0e0e0;
  position: sticky;
  top: 61px;
  z-index: 999;
}

/* Search Bar */
.search-bar {
  display: flex;
  align-items: center;
  background-color: #f5f7fa;
  border-radius: 25px;
  padding: 8px 15px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  width: 100%;
  max-width: 500px;
}

.search-bar .search-icon {
  font-size: 20px;
  color: #888;
  margin-right: 10px;
}

.search-bar input {
  border: none;
  background: none;
  outline: none;
  width: 100%;
  font-size: 14px;
  color: #333;
}

.search-bar input::placeholder {
  color: #aaa;
}

/* Filters */
.filters {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.filter-item {
  display: flex;
  align-items: center;
  background-color: #f5f7fa;
  border-radius: 20px;
  padding: 6px 12px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
}

.filter-item .filter-icon {
  font-size: 18px;
  color: #888;
  margin-right: 8px;
}

.filter-item input {
  border: none;
  background: none;
  outline: none;
  font-size: 13px;
  color: #333;
  width: 120px;
}

.filter-item input::placeholder {
  color: #aaa;
}
/* Filter Dropdown */
.filter-dropdown {
  position: relative;
}

.filter-button {
  display: flex;
  align-items: center;
  background-color: #f5f7fa;
  border: none;
  border-radius: 20px;
  padding: 8px 14px;
  font-size: 13px;
  color: #333;
  cursor: pointer;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
  transition: background-color 0.2s ease;
}

.filter-button:hover {
  background-color: #e8ecef;
}

.filter-button .filter-icon {
  font-size: 18px;
  color: #888;
  margin-right: 8px;
}

.filter-options {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  padding: 10px;
  z-index: 1000;
  max-height: 200px;
  overflow-y: auto;
  min-width: 200px;
}

.filter-option {
  display: flex;
  align-items: center;
  padding: 6px 10px;
  font-size: 13px;
  color: #333;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.filter-option:hover {
  background-color: #f5f7fa;
}

.filter-option input[type="checkbox"] {
  margin-right: 8px;
  accent-color: #625DEC; /* Modern checkbox color */
}


/* Filter Toggle */
.filter-toggle {
  position: relative;
}

.filter-button.active {
  background-color: #3c3c3c;
  color: #ffffff;
}

.filter-button.active .filter-icon {
  color: #ffffff;
}




/* Update Qualified Label Styling */
.qualified-label {
  background-color: #eaffce;
  color: #353535;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 600;
  margin-left: 10px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

/* Ensure participant card hover effect remains modern */
.participant-card {
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.participant-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
}

/* Stats Chips Container */
.stats-chips-container {
  display: flex;
  justify-content: flex-start;
  gap: 20px;
  width: 100%;
  padding: 0px;
  padding-top: 30px;
  padding-bottom: 30px;
  padding-left: 70px;
  background-color: #EEF2F8;
  border-bottom: none;
  position: sticky;
  top: 180px; /* Below header */
  z-index: 998; /* Below search/filter but above content */
}

/* Individual Stat Chip */
.stats-chip {
  width: 160px;
  padding: 20px;
  border-radius: 12px;
  text-align: center;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  background-color: #ffffff;
  color:#747474 ;
  border: 1px solid #ffffff;
  background-color: #ffffff;
}



.stats-chip:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

/* Chip Number */
.stats-chip-number {
  font-size: 32px;
  font-weight: 700;
  margin-bottom: 8px;
}

/* Chip Label */
.stats-chip-label {
  font-size: 14px;
  color: #747474;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.dropzone-container {
    border: 1px dashed #ccc;
    border-radius: 8px;
    padding: 20px;
    text-align: center;
    margin-bottom: 20px;
    cursor: pointer;
    background-color: #ffffff;
    display: flex;
    width: 50%;
    flex-direction: column;
    justify-content: flex-end; 
    align-items: center;
    height: 200px; 
    position: relative;
}

.dropzone-container:hover {
    border-color: #aaa;
}

.dropzone-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    bottom: 10px;
    width: 100%;
}

.uploads {
    margin-top: 20px;
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.upload-image {
    height: 60px;
    width: auto;
    align-self: center;
    margin-top: 20px;
    margin-bottom: 10px;
    padding: 10px;
    border: 1px dashed #000;
  }

  .dropzone-text {
    font-size: 16px;
    color: #787878;
    margin-bottom: 10px;
}



.file-item {
    display: flex;
    justify-content: space-between;
    align-items: center;    
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 8px;
    margin-bottom: 10px;
    background-color: #fff;
    width: 100%;
}

.dropzone-hint {
    font-size: 14px;
    color: #777;
}

.file-name {
    font-size: 14px;
    color: #333;
    word-break: break-word;
    flex-grow: 1;
}

.actions {
    display: flex;
    gap: 10px;
}

.download-button,
.delete-button {
    border: none;
    background: none;
    font-size: 16px;
    cursor: pointer;
    padding: 5px;
    color: rgb(179, 179, 179);
}

.download-button:hover,
.delete-button:hover {
    color: rgb(23, 23, 23);
}

.download-button:disabled,
.delete-button:disabled {
    color: #c6c6c6;
}

.success-tick {
    color: #625DEC;
    font-size: 18px;
    font-weight: bold;
    margin-right: 10px;
}

.progress-bar {
    width: 70%;
    height: 5px;
    background-color: #f3f3f3;
    border-radius: 3px;
    margin-top: 5px;
    margin-right: auto;
}

.progress-bar-fill {
    height: 100%;
    background-color: #625DEC;
    border-radius: 3px;
    transition: width 0.3s ease;
}

.abort-button {
    border: none;
    background: none;
    font-size: 14px;
    color: rgb(82, 82, 82);
    cursor: pointer;
}

.abort-button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.loading-indicator {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}


body {
    font-family: "Nunito", sans-serif;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.dropzone-builder-container {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
}

.dropzone-builder-body {
  font-family: 'Nunito', sans-serif;
  background-color: white;
  width: 90%;
  overflow: hidden;
  padding-left: 60px;
}

.dropzone-builder-header {
  font-family: 'Nunito', sans-serif;
  position: relative;
  z-index: 1;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  background-color: #625DEC;
  color: #ffffff;
  height: 60px;
}

.dropzone-progress-container {
  margin-top: 30px;
  font-family: 'Nunito', sans-serif;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: white;  
  position: relative;
  width: 95%;
  z-index: 10; 
  padding-left: 60px;
}

.dropzone-button-container {
  font-family: 'Nunito', sans-serif;
  display: flex;
  gap: 1rem;
}

.dropzone-button-container-back-button {
  font-family: 'Nunito', sans-serif;
  background-color: white;
  color: #625DEC;
  padding: 0.5rem 1rem;
  border: 2px solid #625DEC;
  border-radius: 8px;
  cursor: pointer;
  box-shadow: 0 2px 0 black;
  transition: transform 0.1s ease-in-out;
  font-weight: bold;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.dropzone-button-container-back-button:hover {
  background-color: #f0f0f0;
}

.dropzone-button-container-back-button:active {
  transform: translateY(2px);
  box-shadow: 0 2px 0 black;
}

.dropzone-progress-indicator {
  font-size: 1rem;
  font-weight: bold;
}

.dropzone-back-button-builder {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    font-size: 14px;
    border: 1px solid rgb(255, 255, 255); 
    cursor: pointer;
    color: #ffffff;
    display: flex;
    align-items: center;
    padding: 5px 5px;
    border-radius: 25px;
    transition: background-color 0.3s ease;
    box-shadow: none; 
    margin-right: 15px;
    margin-left: 15px;
  }

.dropzone-name-input {
    font-family: 'Nunito', sans-serif;
    width: 50%;
    padding: 5px 5px;
    font-size: 20px;
    color: #ffffff;
    background-color: #625DEC;
    border: none; 
    border-radius: 0; 
    font-weight: 500;
    cursor: pointer;
    outline: none;
  }


  .dropzone-button {
    font-family: 'Nunito', sans-serif;
    background-color: #625DEC; 
    color: white; 
    padding: 0.5rem 1rem; 
    border: 2px solid #625DEC; 
    border-radius: 8px;
    cursor: pointer;
    box-shadow: 0 2px 0 black;
    transition: transform 0.1s ease-in-out;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    width: 120px;
    height: 30px;
    text-align: center;
  }
  
  .dropzone-button:hover {
    background-color: #5A54D2; 
  }
  
  .dropzone-button:active {
    transform: translateY(2px); 
    box-shadow: 0 2px 0 black; 
  }
  

  .dropzone-upload-image {
    height: 60px;
    width: auto;
    align-self: center;
    margin-top: 20px;
    margin-bottom: 10px;
    padding: 10px;
    border: 1px dashed #000;
  }

  .dropzone-upload-header-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding-right: 60px;
    align-items: center;
  }
  .dropzone-upload-header{
    font-family: 'Nunito', sans-serif;
    font-size: 24px;
    color: black;
    display: block;
    margin-top: 40px;
    margin-bottom: 30px;
    
  }

  .dropzone-dropzone-container {
    border: 1px dashed #ccc;
    border-radius: 8px;
    padding: 20px;
    text-align: center;
    margin-bottom: 20px;
    cursor: pointer;
    background-color: #ffffff;
    display: flex;
    width: 50%;
    flex-direction: column;
    justify-content: flex-end; 
    align-items: center;
    height: 200px; 
    position: relative;
}

.dropzone-dropzone-container:hover {
    border-color: #aaa;
}

.dropzone-dropzone-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    bottom: 10px; 
    width: 100%;
}

.dropzone-upload-icon {
    width: 50px;
    height: 50px;
    margin-bottom: 10px;
}

.dropzone-dropzone-text {
    font-size: 16px;
    color: #787878;
    margin-bottom: 10px;
}

.dropzone-dropzone-hint {
    font-size: 14px;
    color: #777;
}

.dropzone-uploads-container {
    margin-top: 20px;
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.dropzone-upload-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 8px;
    margin-bottom: 10px;
    background-color: #fff;
    width: 100%;
}

.dropzone-file-name {
    font-size: 14px;
    color: #333;
    word-break: break-word;
    flex-grow: 1;
}

.dropzone-actions {
    display: flex;
    gap: 10px;
}

.dropzone-download-button, .dropzone-delete-button {
    border: none;
    background: none;
    font-size: 16px;
    cursor: pointer;
    padding: 5px;
    color: rgb(179, 179, 179);
}

.dropzone-download-button:hover, .dropzone-delete-button:hover {
  color: rgb(23, 23, 23);
}

.dropzone-download-button:disabled, .dropzone-delete-button:disabled {
  color: #c6c6c6;
}



.dropzone-success-tick {
    color: #625DEC;
    font-size: 18px;
    font-weight: bold;
    margin-right: 10px;
}


.dropzone-progress-bar {
  width: 70%;
  height: 5px;
  background-color: #f3f3f3;
  border-radius: 3px;
  margin-top: 5px;
  margin-right: auto;
}

.progress-bar-fill {
  height: 100%;
  background-color: #625DEC;
  border-radius: 3px;
  transition: width 0.3s ease;
}

.dropzone-abort-button {
  border: none;
  background: none;
  font-size: 14px;
  color: rgb(82, 82, 82);
  cursor: pointer;
}

.dropzone-abort-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}


.dropzone-save-button {
  font-family: 'Nunito', sans-serif;
  background-color: white; 
  color: #625DEC; 
  padding: 0.5rem 1rem; 
  border: 2px solid #625DEC; 
  border-radius: 8px;
  cursor: pointer;
  box-shadow: 0 2px 0 black; 
  transition: transform 0.1s ease-in-out;
  font-weight: bold;
  display: flex;
  align-items: center;
  gap: 0.5rem; 
}

.dropzone-save-button:hover {
  background-color: #f0f0f0; 
}

.dropzone-save-button:active {
  transform: translateY(2px); 
  box-shadow: 0 2px 0 black; 
}

.dropzone-continue-button {
  font-family: 'Nunito', sans-serif;
  background-color: #625DEC; 
  color: white; 
  padding: 0.5rem 1rem; 
  border: 2px solid #625DEC; 
  border-radius: 8px;
  cursor: pointer;
  box-shadow: 0 2px 0 black;
  transition: transform 0.1s ease-in-out;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 120px;
  text-align: center;
}

.dropzone-continue-button:hover {
  background-color: #5A54D2; 
}

.dropzone-continue-button:active {
  transform: translateY(2px); 
  box-shadow: 0 2px 0 black; 
}

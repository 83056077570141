.see-work-container {
    padding: 20px;
    padding-top: 4px;
    background-color: #F3F5F7;
  }
  
  .see-work-flex {
    display: flex;
    gap: 20px;
  }
  
  /* Left transcript list */
  .see-work-transcripts-list {
    flex: 0 0 200px;
    max-height: 80vh;
    overflow-y: auto;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    background-color: #ffffff;
    padding: 10px;
  }
  
  .see-work-transcript-item {
    padding: 8px;
    border-bottom: 1px solid #f1f1f1;
    cursor: pointer;
    transition: background-color 0.2s;
    font-size: 14px;
  }
  
  .see-work-transcript-item:hover {
    background-color: #f9f9f9;
  }
  
  .see-work-transcript-item.selected {
    background-color: #ebf8fa; 
    border-radius: 8px; /* Adjust border-radius as needed */
    padding: 8px;
    transition: background-color 0.2s, border-radius 0.2s;
  }
  



  .transcript-box {
    position: relative; 
    border: 1px solid #e0e0e0;
    background-color: #ffffff;
    border-radius: 8px;
    padding: 15px;
    margin-bottom: 20px;
    width: 70%;
    overflow: visible; /* so arrows/lines can extend out if needed */
  }
  
  .transcript-box-header {
    font-weight: 500;
    color: #666;
    margin-bottom: 10px;
  }

  .see-work-transcript-content {
    flex: 1;
    max-height: 80vh;
    overflow-y: auto;
  }
  
  .transcript-box-content {
    display: flex;
    flex-direction: column;
    gap: 10px;
    color: #505050;
  }
  
  /* Each transcript line (bubble) */
  .sw-transcript-line {
    position: relative;
    overflow: visible;
    display: flex;
    flex-direction: column;
    max-width: 80%;
    padding: 10px;
    margin-bottom: 8px;
    border-radius: 10px;
  }

  
  /* Speaker label above text */
  .sw-transcript-line .speaker {
    font-size: 0.8em;
    color: #666;
    margin-bottom: 5px;
  }
  
  .sw-transcript-line .text {
    font-size: 1em;
    line-height: 1.4;
  }
  
  /* Alternating alignment */
  .sw-transcript-line.user {
    align-self: flex-end;
    /* background-color: #E6E6FA; */
    background-color: none; 
  }
  .sw-transcript-line.echo {
    align-self: flex-start;
    /* background-color: #F0F8FF; */
    background-color: none; 
  }

  .sw-transcript-line.speaker-0 {
    align-self: flex-start;
    /* background-color: #E6E6FA; */
    background-color: none; 
  }

  
  .sw-transcript-line.speaker-1 {
    align-self: flex-end;
     /* background-color: #F0F8FF; */
     background-color: none; 
  }
  .sw-transcript-line.speaker-2 {
    align-self: flex-start;
    background-color: none; 
  }
  .sw-transcript-line.speaker-3 {
    align-self: flex-end;
    background-color: none; 
  }
  .sw-transcript-line.speaker-4 {
    align-self: flex-start;
    background-color: none; 
  }

  
  /* Special messages */
  .transcript-special {
    text-align: center;
    margin: 10px 0;
    color: #888;
  }
  
  /* =============================
     Highlight styling
     ============================= */
  .transcript-highlighted-text {
    background-color: #ffeec9;
    position: relative; 
    border-radius: none;
    border: none;
    padding: 0px;
    margin: 0px;
  }
  
/* summary boxes absolutely placed to the left, stacked vertically. */
.highlight-summary-box {
  position: absolute;
  left: 0%; 
  bottom: 100%;
  background-color: #BDEFFF;
  padding: 5px 10px;
  /* border: 1px solid #a0c8e0; */
  border-radius: 4px;
  font-size: 0.8em;
  white-space: nowrap;
}

/* Vertical spacing between multiple summary boxes in the same line. */
.highlight-summary-box + .highlight-summary-box {
  margin-top: 60px; /* 10px gap */
}

  
.transcript-highlighted-text .highlight-summary-box:nth-of-type(2)::before {
  content: "";
  position: absolute;
  left: -20px;              /* start from the left side of the 2nd summary box */
  top: 10px;                /* tweak so it lines up with highlight text */
  width: 20px;
  height: 1px;
  background-color: #a0c8e0;
  transform: rotate(5deg);  /* tilt slightly */
}

.speaker-indices {
  font-size: 0.8em;
  color: #666;
  margin-left: 0.5em;
}
